import { PixelsAdminProjectMeta } from '@pixels/universal/model/apps/project-meta/pixels-admin-project-meta';
import { EnvironmentsConfig } from '@pixels/universal/model/firebase/environment.model';
import { Phase } from '@pixels/universal/model/phase-model';
import { DEV_PROXY_URL } from '@pixels/universal/model/proxy/proxy.model';

export const environment: EnvironmentsConfig = {
  production: true,
  apiUrl: PixelsAdminProjectMeta.dev?.apiUrl ?? '',
  phase: Phase.dev,
  firebase: PixelsAdminProjectMeta.dev?.firebaseConfig,
  talkPlusAppId: PixelsAdminProjectMeta.dev?.talkPlusAppId ?? '',
  vapidKey: PixelsAdminProjectMeta.dev?.webPushKey ?? '',
  useEmulators: false,
  proxyUrl: DEV_PROXY_URL
};
