import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { isEmpty } from 'lodash-es';
import { Observable, of, switchMap, take } from 'rxjs';

export function tokenInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const auth = inject(Auth);
  return authState(auth).pipe(
    take(1),
    switchMap(u => u?.getIdToken() ?? of(undefined)),
    switchMap(token => {
      if (!isEmpty(token)) {
        req = req.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
      }
      return next(req);
    })
  );
}
